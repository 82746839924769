import { HStack, FilterCheckbox } from 'designsystem';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useCinemaSearch from '../../hooks/useCinemaSearch';

const ProgrammeScheduleFilters: FC = () => {
    const { filterParams, setFilterParams } = useCinemaSearch();

    return (
        <HStack mb={[4, null, null, 6]} spacing={[3, null, null, 6]} flexWrap={['wrap', null, null, 'nowrap']}>
            <FilterCheckbox
                onChange={() => {
                    setFilterParams({
                        expected: true,
                        past: false,
                    });
                }}
                isChecked={filterParams.expected}
            >
                <FormattedMessage defaultMessage="Verwacht" />
            </FilterCheckbox>
            <FilterCheckbox
                onChange={() => {
                    setFilterParams({
                        past: true,
                        expected: false,
                    });
                }}
                isChecked={filterParams.past}
            >
                <FormattedMessage defaultMessage="Archief" />
            </FilterCheckbox>
        </HStack>
    );
};

export default ProgrammeScheduleFilters;
