import { isSameDay, startOfToday } from 'date-fns';
import { useEffect, useMemo, useRef } from 'react';
import { useQueryParams, BooleanParam, withDefault } from 'use-query-params';
import { SearchCinemaScheduleQuery, TicketAvailabilityStatusEnum, useSearchCinemaScheduleQuery } from '../gql/api';
import groupBy from 'lodash.groupby';
import { useRouter } from 'next/router';

const getFilterVars = (filterParams: Record<string, boolean>): { start: string | null; end: string | null } => {
    const [selectedKey] = Object.entries(filterParams).find(([, value]) => value === true);
    switch (selectedKey) {
        case 'expected':
            return {
                start: startOfToday().toISOString(),
                end: null,
            };
        case 'past':
            return {
                start: null,
                end: startOfToday().toISOString(),
            };
        default:
            return {
                start: null,
                end: null,
            };
    }
};

const filterLinkedProgramsOnSameDay = (
    hit: SearchCinemaScheduleQuery['searchCinemaSchedule']['hits'][number],
    curr: SearchCinemaScheduleQuery['searchCinemaSchedule']['hits'][number]
): boolean => {
    const hitOnSameDay = isSameDay(new Date(hit.startOn), new Date(curr.startOn));
    if (hit.composition?.id && curr.composition?.id) {
        return hit.composition?.id === curr.composition?.id && hitOnSameDay;
    }
    return hit.film?.id === curr.film?.id && hitOnSameDay;
};

const hitsByDayMap = (hits: SearchCinemaScheduleQuery['searchCinemaSchedule']['hits']) =>
    hits ? groupBy(hits, hit => new Date(hit.startOn).toDateString()) : undefined;

const useCinemaSearch = () => {
    const [filterParams, setFilterParams] = useQueryParams({
        expected: withDefault(BooleanParam, true),
        past: BooleanParam,
    });
    const vars = useMemo(() => getFilterVars(filterParams), [filterParams]);
    const { data, isLoading, refetch } = useSearchCinemaScheduleQuery(vars);
    const { locale } = useRouter();
    const prevLocale = useRef(locale);

    useEffect(() => {
        if (prevLocale.current !== locale && refetch) {
            // refetch on lang switch
            refetch();
            prevLocale.current = locale;
        }
    }, [locale, prevLocale, refetch]);

    const dedupedOngoingPrograms = useMemo(
        () =>
            data?.searchCinemaSchedule.hits
                .filter(hit => hit.isOngoingProgram)
                .reduce((acc, curr) => {
                    if (
                        acc.some(
                            a => a.film?.id === curr.film?.id && isSameDay(new Date(a.startOn), new Date(curr.startOn))
                        )
                    ) {
                        return acc;
                    }

                    const linkedProgramsOnSameDay = data.searchCinemaSchedule.hits.filter(hit =>
                        filterLinkedProgramsOnSameDay(hit, curr)
                    );
                    const ticketAvailabilityStatus = linkedProgramsOnSameDay?.every(
                        show => show.ticketAvailabilityStatus === TicketAvailabilityStatusEnum.SoldOut
                    )
                        ? TicketAvailabilityStatusEnum.SoldOut
                        : null;

                    return [
                        ...acc,
                        {
                            ...curr,
                            ticketAvailabilityStatus,
                            startOn: linkedProgramsOnSameDay[0]?.startOn ?? curr.startOn,
                            endOn: linkedProgramsOnSameDay[linkedProgramsOnSameDay.length - 1]?.endOn ?? curr.endOn,
                        },
                    ];
                }, [] as SearchCinemaScheduleQuery['searchCinemaSchedule']['hits']),
        [data?.searchCinemaSchedule.hits]
    );

    const hits = useMemo(
        () => dedupedOngoingPrograms?.concat(data?.searchCinemaSchedule?.hits.filter(hit => !hit.isOngoingProgram)),
        [dedupedOngoingPrograms, data?.searchCinemaSchedule?.hits]
    );

    return {
        filterParams,
        setFilterParams,
        hits,
        hitsByDay: hitsByDayMap(hits),
        hitsLoading: isLoading,
        totalHits: data?.searchCinemaSchedule?.totalHits,
    };
};

export default useCinemaSearch;
